<template>
  <b-popover
    :target="target"
    triggers="hover"
    placement="top"
    custom-class="popover-kpi"
  >
    <div class="popover-kpi-container">
      <div v-if="target.includes('theme')">
        DIMENSÃO
      </div>
      <div
        v-if="target.includes('theme')"
        class="box"
      >
        <div>{{ report.dimensions.find(el => parseInt(el.code) === parseInt(report.themes.find(el2 => parseInt(el2.code) === parseInt(target.split("-")[2])).dimension_id)).name }}</div>
        <div>
          Nível Atual:
          {{
            report.dimensions.find(el => parseInt(el.code) === parseInt(report.themes.find(el2 => parseInt(el2.code) === parseInt(target.split("-")[2])).dimension_id)).classification
          }}
        </div>
        <div>Nível Futuro: {{ future.find(el => parseInt(el.dimension_id) === parseInt(report.themes.find(el2 => parseInt(el2.code) === parseInt(target.split("-")[2])).dimension_id)).value }}</div>
      </div>
      <div v-if="target.includes('dimension')">
        DIMENSÃO
      </div>
      <div
        v-if="target.includes('dimension')"
        class="box"
      >
        <div>{{ report.dimensions.find(el => parseInt(el.code) === parseInt(target.split("-")[2])).name }}</div>
        <div>Nível Atual: {{ report.dimensions.find(el => parseInt(el.code) === parseInt(target.split("-")[2])).classification }}</div>
        <div>Nível Futuro: {{ future.find(el => parseInt(el.dimension_id) === parseInt(target.split("-")[2])).value }}</div>
      </div>
      <div v-if="target.includes('theme')">
        TEMA
      </div>
      <div
        v-if="target.includes('theme')"
        class="box"
      >
        <div>
          {{ report.themes.find(el => parseInt(el.code) === parseInt(target.split("-")[2])).name }}
          - Nível {{ report.themes.find(el => el.code === "2").classification }}
        </div>
      </div>
    </div>
  </b-popover>
</template>

<script>
export default {
  name: 'PopoverKPI',
  props: {
    report: Object,
    future: Array,
    target: String,
  },
};
</script>
